<template>
  <vs-popup
    :title="lang.audiences.add.title[languageSelected]"
    :active.sync="activePopup"
    @close="reset"
  >
    <vs-row>
      <vs-col>
        <form-wizard
          title
          subtitle
          ref="wizard"
          class="audience-wizard"
          color="rgba(var(--vs-primary), 1)"
          error-color="#e74c3c"
          stepSize="xs"
        >
          <!-- step 1 -->
          <tab-content
            :title="
              lang.audiences.add.wizard.stepTitles.step1[languageSelected]
            "
            icon="feather icon-share-2"
            class="audience-wizard__tab"
            :before-change="validateStep1"
          >
            <vs-card>
              <h1>
                <strong
                  >{{
                    lang.audiences.add.wizard.step[languageSelected]
                  }}
                  1:</strong
                >
                {{ lang.audiences.add.wizard.steps.step1[languageSelected] }}
              </h1>
              <vs-input
                v-model="audience.name"
                type="text"
                :label-placeholder="
                  lang.audiences.add.wizard.audienceName[languageSelected]
                "
                :danger="hasAudienceNameError"
                :danger-text="audienceNameErrorMsg"
              />

              <vs-select
                v-model="audience.platform"
                :label="
                  lang.audiences.add.wizard.audienceChannel[languageSelected]
                "
                class="mt-5 w-full"
                :danger="validations.step1.emptyPlatform && !audience.platform"
                :danger-text="
                  lang.audiences.add.wizard.validations.emptyPlatform[
                    languageSelected
                  ]
                "
              >
                <vs-select-item
                  v-for="platform in PLATFORMS"
                  :key="platform.value"
                  :value="platform.value"
                  :text="platform.name"
                  :disabled="!platform.enabled"
                  :class="{ disabled: !platform.enabled }"
                  class="platform"
                />
              </vs-select>
            </vs-card>
          </tab-content>

          <!-- step 2 -->
          <tab-content
            :title="
              lang.audiences.add.wizard.stepTitles.step2[languageSelected]
            "
            icon="feather icon-box"
            class="audience-wizard__tab"
            :before-change="validateStep2"
          >
            <vs-card>
              <h1>
                <strong>
                  {{ lang.audiences.add.wizard.step[languageSelected] }}
                  2:
                </strong>
                {{ lang.audiences.add.wizard.steps.step2[languageSelected] }}
              </h1>
              <vs-row>
                <!-- Bots -->
                <vs-col
                  :key="index"
                  v-for="(bot, index) in outgoingBots"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="stretch"
                  vs-xs="12"
                  vs-sm="6"
                  vs-lg="6"
                  class="all-bots-view"
                >
                  <BotItem
                    :bot="bot"
                    :show-actions="false"
                    :is-new-chat="true"
                    :select-bot="selectOutgoingBot"
                  />
                </vs-col>
              </vs-row>
              <p
                class="error-message"
                v-show="validations.step2.emptyIntegration && !integration"
              >
                {{
                  lang.audiences.add.wizard.validations.emptyIntegration[
                    languageSelected
                  ]
                }}
              </p>
            </vs-card>
          </tab-content>

          <!-- step 3 -->
          <tab-content
            :title="
              lang.audiences.add.wizard.stepTitles.step3[languageSelected]
            "
            icon="feather icon-users"
            class="audience-wizard__tab"
            :before-change="validateStep3"
          >
            <vs-card class="flex flex-col">
              <h1>
                <strong>
                  {{ lang.audiences.add.wizard.step[languageSelected] }}
                  3:
                </strong>
                {{ lang.audiences.add.wizard.steps.step3[languageSelected] }}
              </h1>
              <AudienceImporter
                @selectedBot="selectedImportBot"
                @selectedExcel="selectedImportExcel"
              />
              <!-- excel file -->
              <div v-if="newValues.length">
                <vs-divider />
                <vs-table
                  multiple
                  v-model="selected"
                  :data="newValues"
                  :no-data-text="lang.general.noDataText[languageSelected]"
                >
                  <template slot="header">
                    <h2>
                      {{
                        lang.audiences.add.wizard.audienceSelector.selectItems[
                          languageSelected
                        ]
                      }}
                    </h2>
                    <vs-button
                      type="flat"
                      class="float-right"
                      icon-pack="feather"
                      icon="icon-x"
                      color="danger"
                      @click="cancelExcel"
                      >{{
                        lang.apiCalls.edit.cancelText[languageSelected]
                      }}</vs-button
                    >
                  </template>
                  <template slot="thead">
                    <vs-th>
                      {{
                        lang.audiences.add.wizard.audienceSelector.items.name[
                          languageSelected
                        ]
                      }}
                    </vs-th>
                    <vs-th>
                      {{
                        lang.audiences.add.wizard.audienceSelector.items.number[
                          languageSelected
                        ]
                      }}
                    </vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr
                      :data="tr"
                      :key="indextr"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td :data="data[indextr].fullname">{{
                        data[indextr].fullname
                      }}</vs-td>

                      <vs-td :data="data[indextr].senderId">{{
                        data[indextr].senderId
                      }}</vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
              <!-- excel file -->
              <p
                class="error-message"
                v-show="
                  validations.step3.emptyImportType ||
                  (validations.step3.emptyImportValues && !selected.length)
                "
              >
                {{
                  lang.audiences.add.wizard.validations.emptyImportValues[
                    languageSelected
                  ]
                }}
              </p>
            </vs-card>
          </tab-content>

          <!-- step 3 -->
          <!-- <tab-content
              title="Paso 3: canal"
              icon="feather icon-grid"
              class="audience-wizard__tab"
            >
              <vs-card>
                <h1>
                  <strong>
                    {{ lang.audiences.add.wizard.step[languageSelected] }} 3:
                  </strong>
                  {{ lang.audiences.add.wizard.steps.step3[languageSelected] }}
                </h1>
                <vs-select
                  v-model="audience.platform"
                  :label="
                    lang.audiences.add.wizard.audienceChannel[languageSelected]
                  "
                  class="mt-5 w-full"
                >
                  <vs-select-item
                    :key="item"
                    :value="item"
                    :text="item"
                    v-for="item in platforms"
                  />
                </vs-select>
              </vs-card>
          </tab-content>-->

          <!-- step 4 -->
          <tab-content
            :title="
              lang.audiences.add.wizard.stepTitles.step4[languageSelected]
            "
            icon="feather icon-message-circle"
            class="audience-wizard__tab"
            :before-change="validateStep4"
          >
            <vs-card>
              <h1>
                <strong>
                  {{ lang.audiences.add.wizard.step[languageSelected] }}
                  4:
                </strong>
                {{ lang.audiences.add.wizard.steps.step4[languageSelected] }}
              </h1>
              <!-- <AudienceMessageSelector
                :messages="messages"
                v-model="audience.message"
              />-->
              <WhatsAppOutgoing ref="hsm" />
            </vs-card>
          </tab-content>

          <!-- step 5 -->
          <tab-content
            :title="
              lang.audiences.add.wizard.stepTitles.step5[languageSelected]
            "
            icon="feather icon-send"
            class="audience-wizard__tab"
          >
            <vs-card class="flex flex-col">
              <h1>
                <strong>
                  {{ lang.audiences.add.wizard.step[languageSelected] }}
                  5:
                </strong>
                {{ lang.audiences.add.wizard.steps.step5[languageSelected] }}
              </h1>
              <SenderSelector @onSend="send" />
            </vs-card>
          </tab-content>

          <template slot="footer" slot-scope="props">
            <div class="wizard-footer-left">
              <wizard-button
                v-if="props.activeTabIndex > 0"
                :style="props.fillButtonStyle"
                @click.native="props.prevTab()"
              >
                {{ lang.audiences.add.wizard.prev[languageSelected] }}
              </wizard-button>
            </div>
            <div class="wizard-footer-right">
              <wizard-button
                v-if="!props.isLastStep"
                :style="props.fillButtonStyle"
                class="wizard-footer-right"
                @click.native="props.nextTab()"
              >
                {{ lang.audiences.add.wizard.next[languageSelected] }}
              </wizard-button>
            </div>
          </template>
        </form-wizard>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { PLATFORMS } from '../../../../constants/constants'
import { v4 as uuid } from 'uuid'

const AUDIENCE_VALIDATIONS = {
  step1: { emptyName: false, existingName: false, emptyPlatform: false },
  step2: { emptyIntegration: false },
  step3: { emptyImportType: false, emptyImportValues: false }
}

export default {
  name: 'AddAudience',
  components: {
    FormWizard,
    WizardButton,
    TabContent,
    AudienceImporter: () => import('./components/AudienceImporter.vue'),
    SenderSelector: () => import('./components/SenderSelector.vue'),
    // AudienceMessageSelector: () =>
    //   import('./components/AudienceMessageSelector.vue'),
    // Hsm: () => import('./components/Hsm.vue'),
    WhatsAppOutgoing: () => import('./components/WhatsAppOutgoing.vue'),
    BotItem: () => import('../../bot-maker/bots-view/bots/BotItem.vue')
  },
  data() {
    return {
      activePopup: false,
      PLATFORMS,
      importType: null,
      outgoingBots: [],
      validations: AUDIENCE_VALIDATIONS,
      newValues: [],
      selected: []
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('bots', ['getBots']),
    ...mapGetters('audiences', [
      'audiences',
      'audience',
      'integration',
      'hsm',
      'schedule'
    ]),
    ...mapGetters('integrations', ['integrations']),
    isEditing() {
      return !!this.$route.params.userId
    },
    hasAudienceNameError() {
      return (
        (this.validations.step1.emptyName && !this.audience.name) ||
        this.validations.step1.existingName
      )
    },
    audienceNameErrorMsg() {
      let msg = ''

      if (this.validations.step1.emptyName) {
        msg =
          this.lang.audiences.add.wizard.validations.emptyName[
            this.languageSelected
          ]
      } else if (this.validations.step1.existingName) {
        msg =
          this.lang.audiences.add.wizard.validations.existingName[
            this.languageSelected
          ]
      }

      return msg
    }
  },
  methods: {
    ...mapActions('bots', ['GET_BOTS']),
    ...mapActions('integrations', ['GET_INTEGRATIONS']),
    ...mapActions('audiences', ['saveAudience', 'savePush', 'getAudiences']),
    ...mapMutations('audiences', [
      'SET_PUSH_INTEGRATION',
      'SET_PUSH_SCHEDULE',
      'SET_PUSH_NAME',
      'RESET_AUDIENCE',
      'SET_AUDIENCE_ITEMS'
    ]),
    // popup
    openPopup() {
      this.activePopup = true
    },
    closePopup() {
      this.activePopup = false
    },

    // wizard
    navigateToStep(step) {
      this.$refs.wizard.changeTab(0, step)
    },
    isExistingDiffusionName() {
      return (
        this.audiences.find(
          audience => audience.name === this.audience.name
        ) !== undefined
      )
    },
    isStepValid(step) {
      switch (step) {
        case 1:
          this.validations.step1.emptyName = !this.audience.name
          this.validations.step1.existingName =
            this.audience.name && this.isExistingDiffusionName()
          this.validations.step1.emptyPlatform = !this.audience.platform
          break
        case 2:
          this.validations.step2.emptyIntegration = !this.integration
          break
        case 3:
          this.validations.step3.emptyImportType = !this.importType
          this.validations.step3.emptyImportValues = !this.selected.length
          break
        case 4:
          return this.$refs.hsm.validate()
        default:
          break
      }

      return !Object.values(this.validations[`step${step}`]).some(elem => elem)
    },
    async validateStep1() {
      const valid = this.isStepValid(1)
      if (!valid) return
      try {
        this.$vs.loading({ container: '.vs-popup' })
        let getTypes = [this.audience.platform]
        if (this.audience.platform === 'whatsapp') {
          getTypes.push('whatsapp_infobip')
        }
        const payload = { types: getTypes }
        await this.GET_INTEGRATIONS(payload)
        this.getOutgoingBots()
        return valid
      } catch (error) {
        console.warn(error)
      } finally {
        this.$vs.loading.close('.vs-popup > .con-vs-loading')
      }
    },
    validateStep2() {
      return this.isStepValid(2)
    },
    validateStep3() {
      const valid = this.isStepValid(3)
      if (!valid) return false

      this.SET_AUDIENCE_ITEMS(this.selected)
      return valid
    },
    validateStep4() {
      return this.isStepValid(4)
    },

    // components
    // eslint-disable-next-line no-unused-vars
    selectedImportBot(bot) {
      this.importType = 'bot'
      this.navigateToStep(3)
    },
    selectedImportExcel(data) {
      this.importType = 'excel'
      this.newValues = data.values
      this.selected = data.selected
    },
    getOutgoingBots() {
      let botsByServiceId = {}
      this.getBots.forEach(element => {
        botsByServiceId[element.service.id] = element
      })

      this.outgoingBots = this.integrations.filter(
        i => botsByServiceId[i.service._id]
      )
    },
    selectOutgoingBot(bot) {
      this.SET_PUSH_INTEGRATION(bot._id)
      this.navigateToStep(2)
    },
    resetValidations() {
      this.validations = AUDIENCE_VALIDATIONS
    },
    reset() {
      this.RESET_AUDIENCE()
      this.newValues = []
      this.selected = []
      this.senderSelected = null
      this.resetValidations()
      this.navigateToStep(0)
    },
    async send() {
      this.$vs.loading()
      try {
        const audience = await this.saveAudience()
        if (audience && audience._id) {
          if (!this.schedule) {
            // UTC
            // schedule sending in 5 minutes
            const schedule = this.$moment().add(1, 'minutes').toISOString()
            // const timeZone = Helper.getBrowserTimeZone().text
            this.SET_PUSH_SCHEDULE({
              schedule,
              timeZone: '(UTC) Coordinated Universal Time'
            })
          }

          this.SET_PUSH_NAME(uuid())
          await this.savePush()
          await this.getAudiences()
          this.$emit('onSuccess')
          this.$vs.notify({
            title:
              this.lang.audiences.add.wizard.messages.save.success.title[
                this.languageSelected
              ],
            text: this.lang.audiences.add.wizard.messages.save.success.text[
              this.languageSelected
            ],
            color: 'success',
            position: 'top-right'
          })
        }
      } catch (error) {
        this.$vs.notify({
          title:
            this.lang.audiences.add.wizard.messages.save.error.title[
              this.languageSelected
            ],
          text: this.lang.audiences.add.wizard.messages.save.error.text[
            this.languageSelected
          ],
          color: 'danger',
          position: 'top-right'
        })
      } finally {
        this.$vs.loading.close()
        this.closePopup()
        this.reset()
      }
    },
    // save() {
    // this.closePopup()
    // this.$vs.notify({
    //   title: 'Exito',
    //   text: 'Difusion guardado exitosamente',
    //   color: 'success'
    // })
    // this.reset()
    // this.$emit('onSuccess')
    // },
    cancelExcel() {
      this.newValues = []
      this.selected = []
    }
  },
  mounted() {
    this.$vs.loading()
    try {
      this.GET_BOTS()
    } catch (error) {
      console.warn(error)
    } finally {
      this.$vs.loading.close()
    }
  }
}
</script>

<style lang="scss">
.audience-wizard {
  .wizard-header {
    padding: 0;
  }
  &__tab {
    display: flex;
    justify-content: center;
    > .con-vs-card {
      display: flex;
      justify-content: center;
      min-height: 200px;
      padding: 20px;
      h1 {
        font-size: 1.2rem;
        padding-bottom: 20px;
        text-align: center;
      }
      .header-table {
        span {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-content: center;
          flex-grow: 1;
          h2 {
            font-size: 1rem;
            padding-bottom: 20px;
            text-align: center;
          }
        }
      }
      // for big screens
      // flex-basis: 70%;
    }

    .error-message {
      margin-top: 15px;
      display: block;
      height: 19px;
      color: rgba(var(--vs-danger), 1);
      font-size: 1rem;
    }
  }
}
@media screen and (min-width: 1440px) {
  .audience-wizard__tab > .con-vs-card {
    flex-basis: unset;
  }
}
.vue-form-wizard .wizard-card-footer {
  // width: 70%;
  margin: 0 auto;
}
// general fix for vs-select-options inside vs-popup
.vs-select--options {
  z-index: 99999 !important;
  ul li {
    &.platform.disabled {
      button {
        color: #e9e9e9;
        cursor: not-allowed;
      }
    }
  }
}
</style>
